import { FC, useEffect, useState, useRef } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import Collapse from '@mui/material/Collapse';

import { useQueryClient } from 'react-query';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { usePagePathParams } from '@hooks_common/usePagePathParams';
import { useWindowSize } from '@hooks_common/useWindowSize';

import { useLikedCompanies } from '@hooks_iqfu/public/useLikedCompanies';

import { useResetSellerCreateChatProperty } from '@utils_iqfu/chat/resetChatPropertyRecoilState';

import { sellerIsLoggedIn } from '@recoil_iqfu/atoms/public/login_state';
import publicHeaderHeight from '@recoil_iqfu/atoms/public/publicHeaderHeight';
import userUuidValue from '@recoil_iqfu/atoms/public/user_uuid';

import { likedCompanyData } from '@type-def_iqfu/public/likedCompaniesData';

import { NAVIGATION_COMPANIES, NAVIGATION_MEDIA, companyDetailPagePathRegex } from '@constants_iqfu';
import { companyIDRegex } from '@constants_iqfu';
import { useAuth } from '@contexts_common/authContext';

import styles from './Header.module.scss';
import ExternalLink from '../external_link/ExternalLink';

type Props = {
  isMobileMenuOpen: boolean;
  toggleMobileMenuHandler: () => void;
  spRecord?: boolean;
};

const Header: FC<Props> = ({ isMobileMenuOpen, toggleMobileMenuHandler, spRecord = false }) => {
  const router = useRouter();
  const headerRef = useRef(null);
  const [isDropNavContainerOpen, setIsDropNavContainerOpen] = useState(false);
  type DropNavOptions = 'none' | 'top' | 'company' | 'price' | 'media' | 'likes';
  const [isDropNavOpen, setIsDropNavOpen] = useState<DropNavOptions>('none');

  const [hasPageLoaded, setHasPageLoaded] = useState(false);
  const [mobileMenuCompanyOpen, setMobileMenuCompanyOpen] = useState(false);
  const [mobileMenuPriceOpen, setMobileMenuPriceOpen] = useState(false);
  const [mobileMenuMediaOpen, setMobileMenuMediaOpen] = useState(false);
  const setHeaderHeight = useSetRecoilState(publicHeaderHeight);

  const [isLoggedIn, setIsLoggedIn] = useRecoilState(sellerIsLoggedIn);
  const userUuid = useRecoilValue(userUuidValue);

  const { logout } = useAuth();
  const resetUuidValue = useResetRecoilState(userUuidValue);

  const { width: windowWidth } = useWindowSize();

  const isInCompanyDetailPage = companyDetailPagePathRegex.test(router.asPath);

  const queryClient = useQueryClient();

  const { dispatchAddLikedCompaniesAction, useGetLikedCompanies } = useLikedCompanies();

  const { data: fetchedLikedCompaniesData } = useGetLikedCompanies({
    isLoggedIn,
    userUuid,
  });
  const likedCompanies: likedCompanyData[] = fetchedLikedCompaniesData?.favorite_companies;

  const { resetAllCreatePropertiesState } = useResetSellerCreateChatProperty();

  const startChatWithCompany = async () => {
    const companyId = Number(companyIDRegex.exec(router.asPath)?.[1]);

    const checkIsLiked = () => {
      return likedCompanies?.filter((company) => company.company_id === companyId).length > 0;
    };

    if (companyId && !checkIsLiked()) {
      const pageLabel = 'companies_detail';
      await dispatchAddLikedCompaniesAction({
        isLoggedIn,
        userUuid,
        companyId: companyId,
        component: 'Header',
        pageLabel,
      });
      const getLikedCompaniesQuery = ['getFavoriteCompanies', isLoggedIn];
      await queryClient.invalidateQueries(getLikedCompaniesQuery);
    }
  };

  useEffect(() => {
    if (headerRef?.current) {
      // @ts-ignore
      const { height } = headerRef.current.getBoundingClientRect();
      setHeaderHeight(height);
    }
  }, [headerRef, windowWidth]);

  const dropNavOpenHandler = (option: DropNavOptions) => {
    setIsDropNavContainerOpen(true);
    setIsDropNavOpen(option);
  };

  const deactiveDropdown = () => {
    setIsDropNavContainerOpen(false);
    setIsDropNavOpen('none');
  };

  const logoutHandler = async () => {
    await logout('seller');
    resetAllCreatePropertiesState();
    resetUuidValue();

    if (isMobileMenuOpen) toggleMobileMenuHandler();
    setIsLoggedIn(false);
  };

  const mobileLinkClick = () => {
    toggleMobileMenuHandler();
  };

  //isLoggedInのrecoilのstateがssr時の初期値とstorageの値で変わりhydration errorになるので、マウント後にisLoggedInの値を確認する
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
    (async () => {
      setHasPageLoaded(true);
    })();
  }, []);

  const pathParam = usePagePathParams();
  const mediaLinkQueryParam = `?utm_source=iqfu&utm_medium=referral&utm_campaign=header&utm_content=${pathParam}`;

  return (
    <div className={styles.container} onMouseLeave={() => deactiveDropdown()} ref={headerRef}>
      <header className={`${styles.header} ${spRecord ? styles.sp_record : ''}`}>
        <div className={styles.header_inner}>
          <div className={styles.header_logo}>
            <Link href="/" prefetch={false}>
              <img src="/images/iqfu/logo.svg" alt="イクラ不動産" />
            </Link>
          </div>
          {/* mobile用ナビ*/}
          <div className={styles.mobile_only_auth_content_link} style={{ display: spRecord ? 'none' : 'block' }}>
            {isMounted && isLoggedIn ? (
              <Link className={styles.auth_green_button_mobile} prefetch={false} href={`/chat/talk`}>
                売却相談チャット
              </Link>
            ) : (
              <button
                className={styles.auth_green_button_mobile}
                onClick={async () => {
                  if (isInCompanyDetailPage) {
                    await startChatWithCompany();
                  }
                  router.push('/signup');
                }}
              >
                売却相談スタート
              </button>
            )}
          </div>

          {/* mobile用ナビ*/}
          <button
            className={`${styles.mobile_menu_toggle} ${isMobileMenuOpen ? styles.is_active : ''} ${
              spRecord ? styles.sp_record : ''
            }`}
            onClick={toggleMobileMenuHandler}
          >
            <span className={styles.mobile_menu_toggle_line} />
            <span className={styles.mobile_menu_toggle_line} />
            <span className={styles.mobile_menu_toggle_line} />
          </button>
          <div
            className={`${styles.mobile_menu} ${isMobileMenuOpen ? styles.is_active : ''} ${
              hasPageLoaded ? styles.has_initialized : ''
            }`}
          >
            <div className={styles.mobile_auth_button_container}>
              {isMounted && !isLoggedIn && (
                <>
                  <Link
                    className={styles.auth_gray_button_mobile}
                    prefetch={false}
                    href={`/login`}
                    onClick={() => mobileLinkClick()}
                  >
                    ログイン
                  </Link>
                  <Link
                    className={styles.auth_green_button_mobile}
                    onClick={() => mobileLinkClick()}
                    prefetch={false}
                    href={`/signup`}
                  >
                    売却相談スタート
                  </Link>
                </>
              )}
              {isMounted && isLoggedIn && (
                <>
                  <Link
                    className={styles.auth_green_button_mobile}
                    onClick={() => mobileLinkClick()}
                    prefetch={false}
                    href={`/chat/talk`}
                  >
                    売却相談チャット
                  </Link>
                  <button onClick={logoutHandler} className={styles.auth_gray_button_mobile}>
                    ログアウト
                  </button>
                </>
              )}
            </div>
            <div className={styles.mobile_nav_container}>
              <div className={styles.mobile_nav_block}>
                <div
                  className={`${styles.mobile_nav_toggle} ${mobileMenuCompanyOpen ? styles.is_active : ''}`}
                  onClick={() => setMobileMenuCompanyOpen(!mobileMenuCompanyOpen)}
                >
                  不動産会社を探す
                </div>
                <Collapse in={mobileMenuCompanyOpen}>
                  <ul className={styles.mobile_nav_list}>
                    {NAVIGATION_COMPANIES.flat().map((item, index) => (
                      <li key={index} className={styles.mobile_nav_item}>
                        <Link href={item.link} onClick={() => mobileLinkClick()}>
                          {item.text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Collapse>
              </div>
              <div className={styles.mobile_nav_block}>
                <div
                  className={`${styles.mobile_nav_toggle} ${mobileMenuPriceOpen ? styles.is_active : ''}`}
                  onClick={() => setMobileMenuPriceOpen(!mobileMenuPriceOpen)}
                >
                  物件の価格を知る
                </div>
                <Collapse in={mobileMenuPriceOpen}>
                  <ul className={styles.mobile_nav_list}>
                    <li className={styles.mobile_nav_item}>
                      <Link prefetch={false} href="/landhouses" onClick={() => mobileLinkClick()}>
                        土地・戸建価格
                      </Link>
                    </li>
                    <li className={styles.mobile_nav_item}>
                      <Link onClick={() => mobileLinkClick()} prefetch={false} href="/mansions">
                        マンション価格
                      </Link>
                    </li>
                    <li className={styles.mobile_nav_item}>
                      <Link prefetch={false} href="/simulator" onClick={() => mobileLinkClick()}>
                        価格シミュレーター
                      </Link>
                    </li>
                  </ul>
                </Collapse>
              </div>
              <div className={styles.mobile_nav_block}>
                <div
                  className={`${styles.mobile_nav_toggle} ${mobileMenuMediaOpen ? styles.is_active : ''}`}
                  onClick={() => setMobileMenuMediaOpen(!mobileMenuMediaOpen)}
                >
                  お家の売却を学ぶ
                </div>
                <Collapse in={mobileMenuMediaOpen}>
                  <div className={styles.mobile_media_nav_top}>お家の売却 まずはここから</div>
                  <ul className={styles.mobile_nav_list}>
                    <li className={styles.mobile_nav_item}>
                      <Link prefetch={false} href="/tips-to-choose-companies" onClick={() => mobileLinkClick()}>
                        お家を高く売るための不動産屋選びのコツ
                      </Link>
                    </li>
                    <li className={styles.mobile_nav_item}>
                      <Link
                        prefetch={false}
                        href="/features-of-high-selling-houses-lands"
                        onClick={() => mobileLinkClick()}
                      >
                        高く売れる一戸建て・土地の特徴&査定方法の基礎知識
                      </Link>
                    </li>
                    <li className={styles.mobile_nav_item}>
                      <Link
                        prefetch={false}
                        href="/features-of-high-selling-mansions"
                        onClick={() => mobileLinkClick()}
                      >
                        高く売れるマンションの特徴&査定方法の基礎知識
                      </Link>
                    </li>
                  </ul>
                  <div className={styles.mobile_media_nav_top}>お家の売却 こんなときどうするの？</div>
                  <ul className={styles.mobile_nav_list}>
                    {Object.entries(NAVIGATION_MEDIA.outi).map(([key, value]) => (
                      <li key={key} className={styles.mobile_nav_item}>
                        <ExternalLink href={value.url + mediaLinkQueryParam} onClick={() => mobileLinkClick()}>
                          {value.text}
                        </ExternalLink>
                      </li>
                    ))}
                  </ul>
                  <div className={styles.mobile_media_nav_top}>不動産売却基礎知識</div>
                  <ul className={styles.mobile_nav_list}>
                    {Object.entries(NAVIGATION_MEDIA.kiso).map(([key, value]) => (
                      <li key={key} className={styles.mobile_nav_item}>
                        <ExternalLink href={value.url + mediaLinkQueryParam} onClick={() => mobileLinkClick()}>
                          {value.text}
                        </ExternalLink>
                      </li>
                    ))}
                  </ul>
                </Collapse>
              </div>
              <div className={styles.mobile_nav_block}>
                <div className={`${styles.mobile_nav_single}`}>
                  <ExternalLink href={`https://iqrafudosan.com/channel/${mediaLinkQueryParam}`} target="_self">
                    <span>不動産会社向け情報</span>
                  </ExternalLink>
                </div>
              </div>
            </div>
          </div>

          {/* desktop用ナビ*/}
          <nav className={styles.desktop_nav}>
            <ul className={styles.nav_list}>
              <li className={`${styles.nav_item_with_dropdown} ${isDropNavOpen === 'company' ? styles.is_active : ''}`}>
                <Link prefetch={false} href={`/companies`} onMouseEnter={() => dropNavOpenHandler('company')}>
                  不動産会社を探す
                </Link>
              </li>
              <li className={`${styles.nav_item_with_dropdown} ${isDropNavOpen === 'price' ? styles.is_active : ''}`}>
                <div onMouseEnter={() => dropNavOpenHandler('price')}>物件の価格を知る</div>
              </li>
              <li className={`${styles.nav_item_with_dropdown} ${isDropNavOpen === 'media' ? styles.is_active : ''}`}>
                <div onMouseEnter={() => dropNavOpenHandler('media')}>お家の売却を学ぶ</div>
              </li>
              <li className={`${styles.nav_item_without_dropdown}`}>
                <ExternalLink href={`https://iqrafudosan.com/channel/${mediaLinkQueryParam}`} target="_blank">
                  <span>不動産会社向け情報</span>
                </ExternalLink>
              </li>
            </ul>
          </nav>
        </div>
        {isMounted && !isLoggedIn && (
          <div className={styles.auth_content}>
            <Link prefetch={false} href={`/login`} className={styles.login_button_desktop}>
              ログイン
            </Link>
            <button
              className={styles.signup_button_desktop}
              onClick={async () => {
                if (isInCompanyDetailPage) {
                  await startChatWithCompany();
                }
                router.push('/signup');
              }}
            >
              売却相談スタート
            </button>
          </div>
        )}
        {isMounted && isLoggedIn && (
          <div className={styles.auth_content}>
            <Link prefetch={false} href={`/chat/talk`} className={styles.chat_button_desktop}>
              売却相談チャット
            </Link>
            <button onClick={logoutHandler} className={styles.logout_button_desktop}>
              ログアウト
            </button>
          </div>
        )}
      </header>

      <div className={`${styles.drop_down_container} ${isDropNavContainerOpen ? styles.is_active : ''}`}>
        <div
          className={`${styles.drop_down_menu} ${styles.drop_down_menu_companies} ${
            isDropNavOpen === 'company' ? styles.is_active : ''
          }`}
        >
          {NAVIGATION_COMPANIES.map((list, index) => (
            <ul className={styles.nav_link_list} key={index}>
              {list.map((item, index) => (
                <li key={index} className={styles.nav_link_item}>
                  <Link href={item.link}>{item.text}</Link>
                </li>
              ))}
            </ul>
          ))}
        </div>
        <div className={`${styles.drop_down_menu} ${isDropNavOpen === 'price' ? styles.is_active : ''}`}>
          <ul className={styles.nav_link_list}>
            <li className={styles.nav_link_item}>
              <Link prefetch={false} href="/landhouses">
                土地・戸建価格
              </Link>
            </li>
            <li className={styles.nav_link_item}>
              <Link prefetch={false} href="/mansions">
                マンション価格
              </Link>
            </li>
            <li className={styles.nav_link_item}>
              <Link prefetch={false} href="/simulator">
                価格シミュレーター
              </Link>
            </li>
          </ul>
        </div>
        <div className={`${styles.drop_down_menu} ${isDropNavOpen === 'media' ? styles.is_active : ''}`}>
          <div className={styles.media_drop_down}>
            <div className={styles.media_nav_top}>お家の売却 まずはここから</div>
            <div className={styles.media_link_container}>
              <ul className={styles.nav_link_list}>
                <li className={styles.nav_link_item}>
                  <Link prefetch={false} href="/tips-to-choose-companies">
                    お家を高く売るための不動産屋選びのコツ
                  </Link>
                </li>
                <li className={styles.nav_link_item}>
                  <Link prefetch={false} href="/features-of-high-selling-houses-lands">
                    高く売れる一戸建て・土地の特徴&査定方法の基礎知識
                  </Link>
                </li>
                <li className={styles.nav_link_item}>
                  <Link prefetch={false} href="/features-of-high-selling-mansions">
                    高く売れるマンションの特徴&査定方法の基礎知識
                  </Link>
                </li>
              </ul>
            </div>
            <div className={styles.media_nav_top}>お家の売却 こんなときどうするの？</div>
            <div className={styles.media_link_container}>
              <ul className={styles.nav_link_list}>
                {Object.entries(NAVIGATION_MEDIA.outi).map(([key, value]) => (
                  <li key={key} className={styles.nav_link_item}>
                    <ExternalLink href={value.url + mediaLinkQueryParam} target={'_blank'}>
                      {value.text}
                    </ExternalLink>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.media_nav_top}>不動産売却基礎知識</div>
            <div className={styles.media_link_container}>
              <ul className={styles.nav_link_list}>
                {Object.entries(NAVIGATION_MEDIA.kiso).map(([key, value]) => (
                  <li key={key} className={styles.nav_link_item}>
                    <ExternalLink href={value.url + mediaLinkQueryParam} target={'_blank'}>
                      {value.text}
                    </ExternalLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
