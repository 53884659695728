import * as Sentry from '@sentry/browser';
import { useCookies } from 'react-cookie';
import { useQuery } from 'react-query';

import { useAuth } from '@contexts_common/authContext';

const backendBasePath = process.env.NEXT_PUBLIC_DEV_BACKEND_URL;

const useLikedCompanies = () => {
  const [cookies] = useCookies(['expiry', 'access-token', 'client', 'uid', 'user-type']);
  const { tokenValidity } = useAuth();

  const getLikedCompanies = async (uuid: string) => {
    if (!uuid) return;

    const url = `${backendBasePath}/favorite_companies/${uuid}`;

    const result = await fetch(url, {
      method: 'GET',
      mode: 'cors',
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('通信に失敗しました', error);
      });
    return result;
  };

  const addLikedCompany = async (companyId: number, uuid: string, companyStaffProfileId?: number) => {
    const url = `${backendBasePath}/favorite_companies`;

    const result = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        company_id: companyId,
        key: uuid,
        company_staff_profile_id: companyStaffProfileId,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('通信に失敗しました', error);
      });
    return result;
  };

  const deleteLikedCompany = async (companyId: number, uuid: string) => {
    const url = `${backendBasePath}/unfavorite_companies`;

    const result = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        company_id: companyId,
        key: uuid,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('通信に失敗しました', error);
      });
    return result;
  };

  const getLikedCompaniesAfterLogin = async () => {
    const url = `${backendBasePath}/seller/favorite_companies`;

    const result = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        uid: cookies['uid'],
        client: cookies['client'],
        'access-token': cookies['access-token'],
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('通信に失敗しました', error);
      });
    return result;
  };

  const addLikedCompaniesAfterLogin = async (companyId: number, companyStaffProfileId?: number) => {
    const url = `${backendBasePath}/seller/favorite_companies`;

    const result = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        uid: cookies['uid'],
        client: cookies['client'],
        'access-token': cookies['access-token'],
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        company_id: companyId,
        company_staff_profile_id: companyStaffProfileId,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('通信に失敗しました', error);
      });
    return result;
  };

  const deleteLikedCompaniesAfterLogin = async (companyId: number) => {
    const url = `${backendBasePath}/seller/unfavorite_companies`;

    const result = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        uid: cookies['uid'],
        client: cookies['client'],
        'access-token': cookies['access-token'],
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        company_id: companyId,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('通信に失敗しました', error);
      });
    return result;
  };

  const dispatchAddLikedCompaniesAction = async (payload: {
    isLoggedIn: boolean;
    userUuid: string;
    companyId: number;
    component: string;
    pageLabel?: string;
    companyStaffProfileId?: number;
  }) => {
    if (payload.isLoggedIn) {
      await addLikedCompaniesAfterLogin(payload.companyId, payload.companyStaffProfileId);
    } else {
      await addLikedCompany(payload.companyId, payload.userUuid, payload.companyStaffProfileId);
    }
  };

  const useGetLikedCompanies = (payload: { isLoggedIn: boolean; userUuid: string }) => {
    const getLikedCompaniesQuery = ['getFavoriteCompanies', payload.isLoggedIn];

    const dispatchGetLikedCompanies = async () => {
      if (payload.isLoggedIn) {
        return await getLikedCompaniesAfterLogin();
      } else {
        return await getLikedCompanies(payload.userUuid);
      }
    };

    return useQuery(getLikedCompaniesQuery, () => dispatchGetLikedCompanies(), {
      enabled: tokenValidity !== 'unvalidated',
      staleTime: 1000,
    });
  };

  return {
    getLikedCompanies,
    addLikedCompany,
    deleteLikedCompany,
    getLikedCompaniesAfterLogin,
    addLikedCompaniesAfterLogin,
    deleteLikedCompaniesAfterLogin,
    dispatchAddLikedCompaniesAction,
    useGetLikedCompanies,
  };
};

export { useLikedCompanies };
