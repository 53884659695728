import { FC, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { useMediaQuery } from '@mui/material';

import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { v4 as uuid } from 'uuid';

import { useDebounce } from '@hooks_common/useDebounce';
import { usePagePathParams } from '@hooks_common/usePagePathParams';
import { usePageViewLogs } from '@hooks_common/usePageViewLogs';

import ChatMenu from '@components_iqfu/chat/seller/chat_menu/ChatMenu';
import DialogWrapper from '@components_iqfu/public/dialog/DialogWrapper';
import LeaseBackCautionsDialog from '@components_iqfu/public/dialog/leaseback_cautions/LeaseBackCautionsDialog';
import Header from '@components_iqfu/public/header/Header';

import { isInMobileTalkRoomFlag } from '@recoil_iqfu/atoms/chat/talk/isInMobileTalkRoomFlag';
import { leaseBackCautionDialogOpenAtom } from '@recoil_iqfu/atoms/public/leaseback_cautions_dialog_open';
import { sellerIsLoggedIn } from '@recoil_iqfu/atoms/public/login_state';
import userUuidValue from '@recoil_iqfu/atoms/public/user_uuid';

import { MediaQuery } from '@constants_common';
import { useAuth } from '@contexts_common/authContext';

import styles from './PublicPageContainer.module.scss';

type Props = {
  spRecord?: boolean;
  children: React.ReactNode;
};

const PublicPageContainer: FC<Props> = ({ children, spRecord = false }) => {
  const router = useRouter();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const [windowScrollPosition, setWindowScrollPosition] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(sellerIsLoggedIn);

  const [leasebackCautionDialogOpen, setLeasebackCautionDialogOpen] = useRecoilState(leaseBackCautionDialogOpenAtom);

  const [userUuid, setUserUuid] = useRecoilState(userUuidValue);
  const [isInMobileTalkRoom, setIsInMobileTalkRoom] = useRecoilState(isInMobileTalkRoomFlag);

  const isMobile = useMediaQuery(MediaQuery.lg);

  useEffect(() => {
    if (isMobile && router.query.roomId) {
      setIsInMobileTalkRoom(true);
    } else {
      setIsInMobileTalkRoom(false);
    }
  }, [router, isMobile]);

  const { tokenValidity, validateToken } = useAuth();

  const pathParam = usePagePathParams();
  const debouncedPath = useDebounce(router.asPath, 200);

  //validate token
  //publicページはランディング時のみ
  //要ログインページではすべてのページ遷移でおこなう
  const { data: _validateTokenRequestResponse } = useQuery(
    ['isAuthenticated', userUuid, debouncedPath],
    () =>
      tokenValidity === 'unvalidated' || tokenValidity === 'valid'
        ? validateToken({
            page_path: pathParam,
            sid: userUuid,
          })
        : false,
    {
      staleTime: 100,
      enabled: !!userUuid && (tokenValidity === 'unvalidated' || tokenValidity === 'valid'),
    },
  );

  const authChecked = tokenValidity !== 'unvalidated';

  useEffect(() => {
    if (authChecked) {
      setIsLoggedIn(tokenValidity === 'valid');
    }

    if (authChecked && tokenValidity !== 'valid') {
      //chatのパスでログインしていなかったならloginページにリダイレクト
      const isInChatRoutes = router.pathname.indexOf('/chat') !== -1;
      const isBrowser = () => typeof window !== 'undefined';
      if (isBrowser() && isInChatRoutes) {
        const redirectUrl = encodeURIComponent(router.asPath);
        router.replace(`/login?redirect=${redirectUrl}`);
      }
    }
  }, [tokenValidity, authChecked]);

  useEffect(() => {
    if (router.isReady) {
      if (!userUuid) {
        setUserUuid(uuid());
      }

      if (leasebackCautionDialogOpen) {
        setLeasebackCautionDialogOpen(false);
      }
    }
  }, [router]);

  const toggleMobileMenuHandler = () => {
    if (isMobileMenuOpen) {
      setIsMobileMenuOpen(false);
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      window.scroll({
        top: windowScrollPosition,
      });
    } else {
      const { scrollY } = window;
      setIsMobileMenuOpen(true);
      setWindowScrollPosition(scrollY);
      document.body.style.top = `-${scrollY}px`;
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    }
  };

  const { createPageViewLog } = usePageViewLogs();

  const { data: _createPageViewLogSuccess } = useQuery(
    ['createPageViewLog', userUuid, debouncedPath],
    () =>
      createPageViewLog({
        sid: userUuid,
        path_with_query: router.asPath,
      }),
    {
      staleTime: 200,
      enabled: !!userUuid,
      refetchOnWindowFocus: false,
    },
  );

  //isLoggedInのrecoilのstateがssr時の初期値とstorageの値で変わりhydration errorになるので、マウント後にisLoggedInの値を確認する
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => setIsMounted(true), []);

  return (
    <div
      className={`${styles.container} ${isInMobileTalkRoom ? styles.is_in_mobile_talk_room : ''} ${
        spRecord ? styles.sp_record : ''
      }`}
    >
      {/* モバイルの売主不動産会社トーク画面ではヘッダーとチャットメニュー表示しない */}
      {isInMobileTalkRoom ? (
        <></>
      ) : (
        <>
          <Header
            isMobileMenuOpen={isMobileMenuOpen}
            toggleMobileMenuHandler={toggleMobileMenuHandler}
            spRecord={spRecord}
          />
          {isMounted && isLoggedIn && <ChatMenu />}
        </>
      )}
      <DialogWrapper open={leasebackCautionDialogOpen} onClose={() => setLeasebackCautionDialogOpen(false)}>
        <LeaseBackCautionsDialog />
      </DialogWrapper>
      {children}
    </div>
  );
};

export default PublicPageContainer;
